@font-face {
  font-family: 'CtxMenuFont';
  font-style: normal;
  font-weight: 300;
  src: local('Segoe UI');
}

/* Background that covers entire page when right clicking, invisible by default */
#ctx-menu-background {
  /* background-color: rgba(0, 0, 0, 0.1); Un-comment to tint entire page darker when context menu appears */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}

/* - - - - - - -
  Context Menu 
- - - - - - - - */

.ctx-menu-wrapper {
  background-color: rgb(250, 250, 250);
  box-shadow: 4px 4px 3px -2px rgba(0, 0, 0, .1);
  color: black;

  padding: 0.5rem 0 0.5rem 0;
  border: 1px solid #b1b1b1;
  border-radius: 1px;

  font-family: "CtxMenuFont";
  font-size: 1.3rem;

  /* Make all the elements un-selectable */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Do not edit these properties */
  position: absolute;
  z-index: 999;
  white-space: nowrap;

}

/* - - - - - - -
  Context Menu Items 
- - - - - - - - */

.ctx-menu-item {
  padding: 2px 10px 2px 10px;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 0.1s;
}

.ctx-menu-item:hover {
  background-color: rgba(0,0,0,0.13);
}

.ctx-menu-item:active {
  transition: background-color 0s;
  background-color: rgba(0,0,0,0.2);
}

.ctx-menu-separator {
  height: 1px;
  margin: 3px 0 3px 0;
  background-color: rgba(0, 0, 0, 0.2);
}

/* - - - - - - -
      Icons
- - - - - - - - */

.ctx-menu-hasIcon img {
  min-width: 16px;
  max-width: 16px;
  max-height: 16px;
  position: relative;
  margin-right: 8px;
  pointer-events: none;
  user-select: none;
}

.ctx-menu-item img[src] {
  float: left;
}

.ctx-menu-hasIcon .ctx-menu-item {
  vertical-align: middle;
  max-height: 20px;
}


/* - - - - - - -
    Dark Mode
- - - - - - - - */

@media (prefers-color-scheme: dark){
  .ctx-menu-wrapper {
    background-color: rgb(36, 39, 40);
    color:white;
    border-color: #464646;
  }
  .ctx-menu-item{
    transition: background-color 0s;
  }
  .ctx-menu-item:hover{
    background-color: rgb(211, 216, 219);
    color: black;
  }
  .ctx-menu-separator {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ctx-menu-item .ctx-menu-darkInvert{
    filter: invert(1);
  }
  .ctx-menu-item:hover .ctx-menu-darkInvert{
    filter: invert(0);
  }
}