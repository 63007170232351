 @import url(animations.css);
 @import url(ctxmenu.css);
/*Import animations stylesheet*/

/* BY ALEX */

/*This is the stylesheet for the index. Includes specific styles not included in the base stylesheet*/


/* || GENERAL STYLES*/
html {
    font-size: 10px; /*use relative font-size values. This will make things much more convenient especially when trying to make it compatiable with all devices*/
    font-family: 'Segoe UI', Helvetica, sans-serif; /*San-serif as a fallback for older browsers*/
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
    margin: 0rem 0rem 0rem 0rem;
    background-color: rgb(242,242,242);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    min-height: 100vh;
}

header {
    font-size: 1.5rem;
    background-color: white;
    z-index: 3;
    padding: 0;
    border-bottom: 0.1rem solid rgba(242,242,242,1);
}

.navigation {
    top: 0;
    box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
    position: sticky;
    z-index: 1;
}


footer {
    font-size: 1.2rem;
    background-color: black;
    color: white;
    display: block;
    line-height: 1.5;
    display: none;
    margin-block-start: 20rem;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
}

main {
    padding-block-start: 2rem;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
}

@media screen and (max-width: 999px) {

    /*Makes padding less on mobile*/
    main {
        padding-inline: 0rem;
    }
}

h1 {

}

h2 {
    margin-block-start: 0;
    margin-block-end: 0.5rem;
}

p {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    line-height: 1.7;
}

b {
    font-size: 2.5rem;
    font-weight: bolder;
}

hr {
    background-color: #DDDDDD;
    block-size: 0.05rem;
    border: none;
    margin: 1rem 0 1rem;
}

textarea {
    resize: none;
}

*[title] {
    cursor: pointer;
}

.clickable_button {
    color: white;
    transition: all 0.5s ease-out;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.5rem;
    background-color: inherit;
    border: 0;
    padding: 1rem 2rem 1rem 2rem;
    user-select: none;
    margin-block-start: 1rem;
    min-inline-size: 10rem;
    z-index: 0;
    background-color: rgba(29, 98, 149, 1);
    border-radius: 2rem;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
}

.clickable_button.no_box {
    background-color: transparent;
    color: rgba(29, 98, 149, 1);
    box-shadow: none;
}

.clickable_button:hover {
    transition: color 0.3s ease-in, background-color 0.3s ease-in, box-shadow 0.1s ease-in 0.1s;
    color: white;
    background-color: black;
    box-shadow: 0 6px 10px rgb(0 0 0 / 25%);  

}

.clickable_button.no_box:hover {
    transition: unset;
    background-color: transparent;
    box-shadow: none;
    color: black;
    transition: color 0.3s ease-in;
}



.exit_button {
    border-radius: 50%;
    padding: 0.5em;
    width: 4rem;
    height: 4rem;
    border: 0;
    background-color: transparent;
    float: right;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;

}

.exit_button::after {
    content: "\00d7";
    color: rgba(29, 98, 149, 1);
    font-size: 4rem;
    line-height: 5rem;
    transition: color 0.7s ease-in;
    display: inline-block;
}


.exit_button_container {
    block-size: 3rem;
}
.exit_button:hover {
    color: black;
    transition: color 0.7s ease-in;
}

.clickable_button:focus {
    outline: 0.2rem dotted;
    outline-color: rgba(0,0,0, 0.4);
    transition: outline-color 0.3s ease-in;
}


.icon_button {
    border: none !important;
    inline-size: 3rem;
    block-size: 3rem;
    object-fit: contain;
    border-radius: 50%;
    padding: 0.5rem;
    font-size: 2rem;
    display: flex;
    line-height: 3rem;
    justify-content: center;
    align-items: center;
    overflow: visible;
    cursor: pointer;
    color: white;
    background-color: rgb(29, 98, 149);
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    /* background-color: #f9fcff;
    background-image: linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%); */

    transition: transform 250ms, background-color 250ms;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C0C0C0', endColorstr='#B1B1B1', GradientType=0);
}

.icon_button.square {
    border-radius: 0.1rem;
}

.icon_button.no_transition {
    transition: background-color 250ms;
}

.icon_button.no_transition:hover {
    transition: background-color 0.2s ease-in;
    transform: none;
}

.icon_button:hover {
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in 0.07s, background-color 0.2s ease-in;
    transform: translateY(-0.5rem);
    background-color: black;
    box-shadow: 0 8px 16px rgb(0 0 0 / 40%);  
}

.icon_button_container {
    display: flex;
    justify-content: center;
    gap: 1rem;

}

.icon_button_container_large {
    gap: 1rem;
    display: flex;
    justify-content: center;
}

.icon_button_container_large .icon_button {
    font-size: 2.5rem;
    line-height: 2.5rem;
    height: 4rem;
    width: 4rem;
}

.exit_button:hover {
    background-color: white;
    color: rgb(242,242,242);
  }

/* || DROPDOWN */
.drop-down {
    font-size: 1.5rem;
    height: 2rem;
  }
  
  .drop-down-options {
    opacity: 0;
    position: absolute;
  }
  
  .drop-down label {
    gap: 0.5rem;
    padding-block-end: 0.5rem;
    cursor: pointer;
  }
  
  .drop-down label .chevron-icon {
  }
  
  .drop-down .chevron-icon {
    width: 0.7rem;
  }
  
  .drop-down .chevron-icon svg {
    transition: transform 0.2s ease-in;
  }
  
  .drop-down .chevron-icon.flipped svg {
    transform: rotate(-90deg);
  }
  
  
  .drop-down .drop-down-options.show {
    overflow: visible;
    background-color: #edecec;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 0.2rem;
    opacity: 1;
    transition: opacity 0.3s ease-in, box-shadow 0.2s ease-in;
  }
  
  .drop-down-options ul.nav-link-list {
    padding: 0.5rem;
  }

  ul.nav-link-list li {
    padding: 0.3rem 0;
  }
  
  
  .drop-down-options option {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.1s ease-in;
  }
  
  .drop-down-options option:hover {
    background-color: #dedcdc;
  }
  

.drop_down_nav {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.link_list.drop_down_header span {
    margin-inline-end: 1rem;
    margin-block-end: 1rem;
}
.link_list.drop_down_header span span{
    padding-inline-start: 1rem;
}

.link_list.drop_down_header {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    background-color: white;
    /* overflow: hidden; */
    justify-content: flex-start;
    overflow: hidden;
    max-height: 1rem;
    padding-block-end: 2rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    transition: max-height 0.3s ease-in, box-shadow 0.2s ease-in;
}

.drop_down_header.show_dropdown_header {
    max-height: 20rem;
    box-shadow: 0px 12px 2px rgb(0 0 0 / 20%);
    transition: max-height 0.4s ease-in, box-shadow 0.2s ease-in 0.3s;
}

.link_list.drop_down_header li{
    padding-inline: 1rem;
}

.Dropdown-root {
    block-size: 3rem;
    user-select: none;
}

.Dropdown-control {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem 1rem 0.5rem;
}

.drop_down_button {
    block-size: 3.5rem;
}

.drop_down_button .Dropdown-control {
    background-color: rgb(29, 98, 149);
    color: white;
    margin-block-end: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    transition: box-shadow 0.4s ease-in;
}

.drop_down_button .Dropdown-control:hover {
    box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
    transition: box-shadow 0.2s ease-in-out;

}

.drop_down_button .Dropdown-placeholder {
    display: inline;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.Dropdown-arrow-wrapper {
    margin-left: auto;
    padding-inline-start: 1.5rem;
    min-inline-size: 2rem;
    /* margin-block-start: 0.5rem; */
}

.Dropdown-menu {
    position: relative;
    z-index: 2;
    inline-size: 100%;
    border-radius: 2rem;
    box-shadow: 0px 14px 15px 0px rgb(0 0 0 / 20%);
    animation: drop_down_reveal both 0.2s ease-in;
}

.Dropdown-menu .Dropdown-option {
    font-size: 1.5rem;
    background-color: white;
    padding: 0.5rem 1rem 0.5rem 1rem;
    z-index: 2;
    cursor: pointer;
    transition: background-color 0.3s ease-out;

}

.Dropdown-menu .Dropdown-option:first-child {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}

.Dropdown-menu .Dropdown-option:last-child {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding-block-end: 3rem;
}

.Dropdown-menu .Dropdown-option:hover {
    font-size: 1.5rem;
    background-color: rgb(209, 231, 246);
    transition: background-color 0.35s ease-in;
}



input[type="radio"] {
/* Add if not using autoprefixer */
-webkit-appearance: none;
appearance: none;
/* For iOS < 15 to remove gradient background */
background-color: #fff;
/* Not removed via appearance */
margin: 0;
}

input[type='radio'] {
    appearance: none;
    background-color: #fff;
    border-radius: 50%;
    inline-size: 2rem;
    cursor: pointer;
    block-size: 2rem;
    border: 0.2rem solid black;
    color: rgb(29, 98, 149);
    display: flex;
    justify-content: center;
    align-items: center;
    font: inherit;
    margin-inline: 0.3rem;
    transform: translateY(-0.075rem);
}

input[type="radio"]::before {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem #F4D27A;
}

input[type="radio"]:first-child::before{
    box-shadow: inset 1rem 1rem #AFC1CC;
}

input[type="radio"]:last-child::before{
    box-shadow: inset 1rem 1rem #F37257;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

input[type='radio']:after {
    padding-inline-end: 4rem;
}
.prevent_scroll {
    overflow-Y: hidden;
    position: fixed;
}
.delete_button {
    background-color:#f44336;
}

ul.link_list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    list-style-position: inside;
    padding-inline-start: 0rem !important;
}

.drop_down_logo {
    padding-inline-end: 1rem;
    cursor: pointer;
}

.link_list li{
    text-align: none;
    list-style-type: none;
    display: inline;
    padding-inline-end: 2rem;
}


blockquote {
    font-size: 2rem;
    line-height: 1.5;
    margin-block-end: 1rem;
}


.slide_button {
    border: none !important;
    border-radius: 50%;
    color: black;
    background-color: white;
    border: revert;
    text-decoration: none;
    display: inline;
    font-size: 2.5rem;
    padding: 0.4rem 1.4rem;
}

ul[class] {
    padding-inline-start: 2rem;
    list-style-type: none;
}


.logo {
    max-inline-size: 4vh;
    padding: 1rem 1rem 1rem 1rem;
    margin-inline-end: 1rem;
    display: inline;
}



.end_user_agreements li {
    border-inline-end: 1px solid white;
    margin-inline-end: 1rem;
    padding-inline-end: 1rem;
}

@media screen and (max-width: 999px) {
    .end_user_agreements li {
        font-size: 1rem;
    }
}

.end_user_agreements :last-child {
    border-inline-end: none;
}

.notes + b {
    display: block;
    text-align: right;
}

.footer_copyright {
    font-size: 10px;
}

/* || TIMETABLE*/
.timetable {
    font-size: 1.5rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-block-start: 1rem;
    grid-column: 1/4;
    grid-row: 1;
    text-align: center;
  }

  .timetable [role="tablist"] {
    animation: none; 
  }

  .timetable .rc-tabs-nav-list .rc-tabs-tab {
    animation: none;
  }
  
  .timetable_countdown {
    font-weight: bold;
    font-size: 3rem;
  }

  .timetable_countdown .countdown_title{
    margin: 0;
    font-size: 1.5rem;
    font-weight: normal;
    animation: fade_in 0.3s ease-in;
  }

  .timetable_countdown .countdown_subtitle {
    margin: 0.5rem 0;
  }

  /* .timetable_cycle {
    display: none;
  } */
  
  .timetable_today {
    max-width: 1000px;
    width: 90%;
    margin: auto;
    table-layout: fixed;
    border-collapse: separate;
    padding-inline: 1.5rem;
    text-align: left;
    border-spacing: 0 0.5rem;
  }

  @media screen and (max-width: 999px) {
    .timetable_today {
        width: 90%;
    }
  }
  
  .timetable tbody td {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
  
  .timetable tbody td div {
    font-size: 1rem;
    color: rgba(124, 124, 124, 0.8);
  }
  
  .timetable tbody tr.period_class td {
    transition: background-color 0.4s ease-out;
    background-color: rgba(255,255,255, 0.6);
  }
  
  .timetable .period_break {
    color: rgba(124, 124, 124, 0.8);
    transition: all 0.2s ease-out;
    background-color: rgba(242, 242, 242, 0.7);
  }
  
  .timetable_today tbody tr.period_class:hover td {
    transition: background-color 0.3s ease-in;
    background-color: rgba(209, 231, 246, 0.7);
  
  }
  
  .timetable_today tbody tr.period_class {
    opacity: 0;
    animation: fade-top 0.3s ease-in forwards;
  }

  .timetable_today td:nth-child(2) {
    padding-inline-end: 1rem;
    text-align: right;
    border-top-right-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    width: 20%;
  }
  
  .timetable_today td:nth-child(1) {
    text-align: right;
    border-top-left-radius: 0.7rem;
    border-bottom-left-radius: 0.7rem;
    width: 80%;
  }
  
  .timetable_today td:first-child {
    padding-inline-start: 1rem;
    text-align: left;
    width: 50%;
  }
/* || TIMETABLE WEEKLY */
.week_container {
    opacity: 0;
    display: flex;
    width: 80%;
    margin: auto;
    flex-direction: column;
    animation: bounce 0.7s ease-in forwards;
}

.week_container:nth-child(2) {
    animation-delay: 0.5s;
}

.week_container:nth-child(3) {
    animation-delay: 1s;
}

.week_container h2{
    text-align: left;
}

.week thead {
    color: rgba(124, 124, 124, 0.8);
    text-transform: uppercase;
  }
  .week {
    margin-block-end: 3rem;
  }

  .week:nth-child(2) {
    animation-delay: 0.5s;
  }

  .week:nth-child(3) {
    animation-delay: 1s;
  }



  .week thead td {
    border-bottom: 2px solid rgb(124,124,124);
  }


  
  .week tbody .day .period_cycle_class {
    display: flex;
    justify-content: center;
  }

  .week tbody .day .period_cycle_empty {
    display: flex;
    justify-content: center;
  }
  .week tbody .day .period_cycle_class td{
    cursor: pointer;
    transition: all 0.2s ease-out;
    background-color: white;
    width: 5rem;
    height: 2rem;
  
  }
  .week tbody .day .period_cycle_empty td{
    cursor: pointer;
    transition: all 0.2s ease-out;
    background-color: rgb(247, 247, 247);
    width: 5rem;
    height: 2rem;
  
  }
  
  .week tbody .day td.hovered_class {
    background-color: #1D6295;
    color: white;
    transition: all 0.5s ease-in;
  }

  .print_button_container {
    block-size: 5rem;
    margin: auto;
  }

  .print_button {
    float: right;
    margin-inline-end: 1rem;
  }

  .period_number, .period_cycle_number {
    color: rgb(124, 124, 124)
  }
  

  @media screen and (max-width: 999px) {
    .week_container {
        width: 100%;
    }
    .week tbody .day tr td.period_number {
        width: 1rem;
      }
    
      .week tbody .day .period_cycle_class td.period_cycle_number {
        width: 1rem;
      }

      .week tbody .day .period_cycle_class td {
        width: 3rem;
      }

      .week tbody .day .period_cycle_empty td {
        width: 1rem;
      }

      .week tbody .day .period_cycle_empty td.period_description {
        width: 3rem;
      }
  }
  


/* || CALENDAR MINI*/
.calendar {
    grid-column: 4/end;
    grid-row: 1;
    height: 85vh;
}

.calendar_full_mobile {
    margin: 0 1rem;
}

#mini_calendar {
    inline-size: 80%;
    border: 1px solid black;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-block-start: 2rem;
}

#mini_events {
    inline-size: 90%;
    padding: 0 0.5rem 0 0.5rem;
}


#mini_events h2 {
    background-color: rgb(29, 98, 149);
    color: white;
    padding: calc(0.5rem + 1px);
    margin: 0 0 0.5rem;
    border-radius: 5px;
}
/*Stylesheet for the external library calendar some edits were made*/
.rolyart-calendar {
    background-color: white;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }
  
  .calendar-body .day.not-current {
    color: #999999;
    background-color: rgb(242,242,242);
  }

  .calendar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.3rem;
    border-bottom: 1px solid #DDDDDD;
  }
  
  .calendar-header .month-year {
    -webkit-box-flex: 1;
        -ms-flex: 1  1 auto;
            flex: 1  1 auto;
    margin: 0;
    margin: 0 1rem 0 1rem;
    top: 0.25rem;
    font-size: smaller;
  }
  
  .calendar-header .calendar-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }  
  
  .calendar-header .calendar-control button.control-current-month {
    margin: 0 8px;
  }
  
  .calendar-header .calendar-control button.control-current-month .current-month {
    width: 1.2rem;
    height: 1.2rem;
    background-color: rgb(29 98 149);
    content: '';
    display: inline-block;
    border-radius: 50%;
  }
  
  .week-days {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    display: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid #DDDDDD;
  }
  
  .week-days div {
    width: calc(100% / 7);
    text-align: center;
  }
  
  .calendar-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 1rem 0 0 0;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  
  .calendar-body .day {
    width: calc(100% / 7);
    text-align: center;
    padding: 0.7rem 0.2rem;
    background-color: white;
    cursor: pointer;
  }
  
  .calendar-body .day span {
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
  }
  
  .calendar-body .day.active {
    color: rgb(29 98 149);
    font-weight: bold;
  }
  
  .calendar-body .day.selected {
    font-weight: bold;
  }
  
  .calendar-body .day.selected span {
    background-color: rgb(29 98 149);
    display: inline-block;
    color: white;
    border-radius: 50%;
    -webkit-animation: pulse .4s ease;
            animation: pulse .4s ease;
  }
  
  .calendar-body .day.hovered {
    background-color: rgba(209,231,246, 0.5);
  }

#events_container {
    overflow-y: auto;
    overflow-x: hidden;
    block-size: 35vh;
    margin-block-end: 1rem;
}

#events_list, #completed_events {
    overflow-x: hidden;
    font-size: 1.5rem;
    padding-inline: 1rem;
    margin: 0;
    /*background-color: rgb(242, 242, 242);*/
}

#mini_events li:hover {
    background-color: rgba(209, 231, 246, 0.7);
    transition: background-color 0.3s ease-out;
}


#events_list li, #completed_events li {
    display: flex;
    overflow: hidden;
    block-size: calc(0.64rem + 0.64rem + 1rem);
    list-style-position: inside;
    padding-block-end: 0.64rem;
    padding-block-start: 0.64rem;
    background-color: white;
    padding-inline-start: 0.5rem;
    background-color: rgba(242,242,242, 0.7);
    /*box-shadow: 0 2px 8px rgb(0 0 0 / 5%);*/
    border-radius: 1rem;
    margin-block-end: 0.9rem;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    white-space: nowrap;
    border-inline-end: 0.7rem inset;
    transition: background-color 0.2s ease-in;
}


#events_list li a, #completed_events li a {
    text-overflow: ellipsis;
    overflow: hidden;
    max-inline-size: calc(100% - 3.5rem);
}

#events_list .Low_priority {
    border-inline-end-color: #AFC1CC;
}

#events_list .Medium_priority {
    border-inline-end-color: #F4D27A;
}

#events_list .High_priority {
    border-inline-end-color: #F37257;
}

#completed_events li {
    border-inline-end: 0.7rem inset rgb(124, 124, 124);
}

#events_list .hovered_item {
    animation: hover_list 0.2s ease-out both;
}

.added_event {
    animation: add_event 0.8s ease-out both;
}

.complete_event {
    animation: complete_event 0.4s ease-out both;
}

#events_list #Add_new_event {
    box-shadow: none;
    background-color: inherit;
}

#events_list li a, #completed_events li a {
    block-size: calc(0.64rem + 0.64rem + 1rem);
    display: inline-block;
}

.event_tooltip {
    content: '';
    font-size: 1.5rem;
    white-space: nowrap;
    text-transform: none;
    inline-size: 50vw;
    background-color: white;
    position: fixed;
    z-index: 2;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    padding: 0 0rem 1rem 0rem;
    top: 50vh;
    right: 25vw;
    transform: translateY(-25rem);
}

.event_tooltip:hover {
    box-shadow: 0 12px 20px rgb(0 0 0 / 40%);
}


.event_tooltip h3 {
    margin: 0;
    margin-block-end: 1rem;
    background-color: rgb(29, 98, 149);
    color: white;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.event_tooltip .event_description {
    padding: 1rem;
    margin: 1rem 1rem 1rem 1rem;
    border-radius: 1rem;
    border: none;
    block-size: 14rem;
    background-color: rgb(242,242,242);
    font-family: 'Segoe UI';
    inline-size: 100%;
    cursor: pointer;
    outline: none;
}

.grid_label {
    display: grid;
    grid-row: 2;
    grid-template-columns: 42% 58%;
    grid-template-rows: auto auto auto;
    width: 50%;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: rgba(209, 231, 246, 0.7);
}

.event_tooltip p {
    color: black;
    background-color: white;
    padding: 0.5rem 0.7rem 0.5rem 0.7rem;
    border-radius: 0.7rem;
}

.event_tooltip label {
    font-weight: bold;
    margin-block-start: 1.2rem;
}

.show_tooltip {
    animation: show_tooltip 0.2s ease-out both;
}

.hide_tooltip {
    animation: show_tooltip 0.2s ease-out both reverse;
}

#calendar_tutorial {
    animation: fade_in_text 0.3s ease-in;
    margin: 2rem;
    color: rgba(124, 124, 124, 0.7);
    position: relative;

}


#completed_events li {
    color: rgb(124,124,124);
    text-decoration: line-through;
}

/* || CALENDAR FULL */

#full_calendar {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 4rem;
    row-gap: 3rem;
    padding-inline: 2rem;
}

.search_and_sort {
    display: flex;
    align-items: center;
    gap: 1rem;
    grid-row: 1;
    grid-column: 1/end;
}

.search_and_sort .sort_drop_down {
    padding: 1rem;
}

.search_bar_container {
    padding-inline-start: 1rem;
    background-color: white;
    inline-size: 51%;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    transition: box-shadow 0.4s ease-in;

}

.search_bar_container:hover {
    box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
    transition: box-shadow 0.2s ease-in-out;
}

.search_bar {
    font-size: 1.5rem;
    outline: none;
    padding: 1rem;
    border: none;
    inline-size: calc(100% - 6rem);

}

.drop_down_container {
    display: flex;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
    border-inline-start: 0.2rem solid transparent;
    border-inline-end: 0.2rem solid transparent;
    max-width: 0rem;
    transition: max-width 0.6s ease-in 0.2s, border-inline-color 0.2s ease-in 0.2s;
}

.drop_down_container .drop_down_button {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
}


.search_and_sort.show_drop_down .drop_down_container{
    max-width: 100rem;
    display: flex;
    border-inline-start: 0.2rem solid rgb(124, 124, 124);
    border-inline-end: 0.2rem solid rgb(124, 124, 124);
    padding: 0 1rem 0 1rem;
    transition: max-width 0.6s ease-in, opacity 0.3s ease-in 0.5s, border-inline-color 0.2s ease-in 0.6s;;
}

.icon_button[title="Show More Options"] svg{
    transition: transform 0.2s ease-in;
}

.search_and_sort.show_drop_down .icon_button[title="Show More Options"] svg{
    transform: rotate(180deg);
    transition: transform 0.2s ease-in 0.4s;
}

.search_and_sort.show_drop_down .drop_down_container .drop_down_button{
    opacity: 1;
    transition: opacity 0.2s ease-in 0.6s;
    pointer-events: all;
}



.drop_down_container .icon_button:hover {
    transform: translateX(0);

}


#calendar_function_container {
    position: fixed;
    padding: 0 2rem 3rem 2rem;
    border-radius: 3rem;
    height: fit-content;
    /* grid-row: 2;
    grid-column: 4/end; */
    left: 65%;
    top: 70vh;
    inline-size: 40rem;
    animation: bounce 0.7s ease-out both;
}

#calendar_function_container .clickable_button.month-year{
    font-size: 3rem;
}

#calendar_function_container .rolyart-calendar {
    background-color: transparent;
    padding: 0;
    
}

#calendar_function_container .rolyart-calendar .calendar-header{
    background-color: transparent;
    padding-block-end: 1rem;
    padding-inline: 0rem;
}

#calendar_container {
    margin-block-end: 2rem;
}



#calendar_function_container .icon_button_container {
    padding: 1rem;
    border-radius: 2rem;
}

#calendar_function_container .icon_button_container .icon_button {
    animation: span 0.4s ease-in 0.1s backwards;
}

#calendar_function_container .icon_button_container .icon_button:nth-child(2) {
    animation: span 0.4s ease-in 0.3s backwards;
}

#calendar_function_container .icon_button_container .icon_button:nth-child(3) {
    animation: span 0.4s ease-in 0.5s backwards;
}

#calendar_function_container .icon_button_container .icon_button:nth-child(4) {
    animation: span 0.4s ease-in 0.7s backwards;
}

#calendar_function_container .icon_button_container .icon_button:nth-child(5) {
    animation: span 0.4s ease-in 0.9s backwards;
}

#calendar_function_container .icon_button_container .icon_button:nth-child(6) {
    animation: span 0.4s ease-in 1.1s backwards;
}


#full_events {
    inline-size: 100%;
    grid-column: 1/4;
}


#events_box {
    inline-size: 100%;
    border-radius: 2rem;
    padding: 1rem;
    overflow-y: auto;
}

#no_events_on_day {
    font-size: 1.5rem;
    color: rgb(124,124,124);
    word-break: break-all;
    text-align: center;
    animation: fade_in 0.5s ease-in;
}

.event_item_container {
    display: flex;
    align-items: center;
    align-items: stretch;
}

.timeline {
    font-size: 2rem;
    width: 23%;
    display: flex;
    gap: 2rem;
}
.days_left {
    inline-size: 12rem;
    block-size: 3rem;
    text-transform: uppercase;
    font-weight: bolder;
    color: rgba(29, 98, 149, 1);
    font-size: 1.5rem;
}

.event_separator {
    inline-size: 100%;
    block-size: 2rem;
}

.event_group {
    margin-block-end: 2rem;
    /* overflow: hidden; */
}

.event_group .event_item_full {
    opacity: 1;
    transition: opacity 0.5s ease-in 1.5s;
}

.event_group.show_event_group {
    transition: max-height 1.5s;
    overflow: hidden;
}

.event_properties .event_property {
    font-weight: bold;
}

.event_group .flex {
    margin-block-end: 2rem;
}


.event_group .event_properties {
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 2rem;
    width: 70%;
}

.event_group .flex .button_container {
    width: 30%;
    display: flex;
    gap: 1rem;
}

.event_group .event_properties div {
    margin-block-end: 0.5rem;
}

.event_group .event_properties div span {
    margin-inline-end: 0.75rem;
}

.event_group.hide_event_group {
    max-height: 3rem;
    overflow: hidden;
}

.event_group.hide_event_group .event_item_full {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.vl {
    border-left: 0.6rem solid rgba(29, 98, 149, 0.7);
    left: 50%;
    margin-left: -0.3rem;
    top: 0;
    cursor: pointer;
    padding-block: 2rem;
    transition: border-left-color 0.7s ease-out;
}

.vl.hover_line {
    border-left-color: rgb(29, 98, 149);
    transition: border-left-color 0.7s ease-out;
}

.event_item_full {
    font-size: 1.5rem;
    background-color: white;
    border-radius: 2rem;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    overflow: hidden;
    inline-size: 55%;
    color: black;
    /* align-items: center; */
    padding: 1rem 2rem 1rem 2rem;
    margin-block-end: 3rem;
    animation: bounce 0.7s ease-in;
}

.search_word {
    animation: show_search 0.3s ease-out 0.7s both;
}

.event_item_full.event_completed {
    opacity: 0.5;
    color: rgb(124,124,124);
    /* transition: opacity 0.5s ease-in; */
    animation: completed_bounce 0.7s ease-in;
}

.event_item_full.event_completed .event_title {
    text-decoration: line-through;
}

.event_item_full.event_completed .button_container .icon_button[title='Edit Event'] {
    pointer-events: none;
    opacity: 0.3;
}

.event_item_full .event_description {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: wrap;
    max-height: 0rem;
    overflow: hidden;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    opacity: 0;
    margin-block-start: 2rem;
    background-color: rgb(242,242,242);
    transition: max-height 0.5s ease-out 0.2s, opacity 0.3s ease-in;
}


.event_title {
    overflow: hidden;
    white-space: nowrap;
    word-break: break-all;
    overflow-wrap: break-word;
    block-size: 3rem;
    text-overflow: ellipsis;
}

.event_description.show_description {
    max-height: 14rem;
    overflow-y: auto;
    opacity: 1;
    transition: max-height 0.5s ease-out, opacity 0.3s ease-in 0.5s;
}

.event_item_info {
    width: 100%;
    height: 10rem;
    overflow-y: auto;
}
.event_item_full .days_left {
    font-size: 5rem;
    margin-inline-end: 1rem;
}

.event_item_container .button_container {
    margin-block-start: 2rem;
    display: flex;
    justify-content: center;
}

.event_item_container .button_container button {
    border: none;
    outline: none;
    font-size: 2rem;
}

.event_item_full button[title='Hide Description'] {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    background-color: white;
    color: rgba(29, 98, 149, 1);
}

.event_item_full button[title='Show Description'] {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
    color: rgba(29, 98, 149, 1);
    background-color: inherit;
}

/* For mobile */
@media screen and (max-width: 999px) {
    .event_group .flex {
        margin: 0;
    }

    .event_group hr {
        margin-block: 0.5rem;
    }
    .event_group .event_item_full .event_description .flex {
        display: block;
        width: 100%;
        margin: 0;
    }

    .event_group {
        margin-top: 4.5rem;
    }

    .event_group .event_properties {
        width: calc(90% + 1rem);
    }
    .event_group .flex .button_container {
        width: 40%;
    }


    .event_item_full .button_container#no_print {
        margin-block: 0;
    }
    .search_and_sort {
        display: block;
    }
    .search_bar_container {
        width: 100%;
    }

    #full_calendar {
        display: block;
    }

    #events_box {
        margin-block-end: 20rem;
    }

    #calendar_function_container {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        z-index: 1;
        width: 90%;
        padding-top: 2rem;
        border-radius: 0;
    }

    #calendar_function_container .calendar-header{
        display: none;
    }

    .icon_button[title="Show More Options"] {
        display: none;
    }


    #calendar_function_container .icon_button_container {
        padding: 0;
    }
    

    #calendar_function_container .clickable_button.month-year {
        font-size: 1.5rem;
    }
    #calendar_function_container #calendar_container {
        padding: 0;
        margin: 0;
    }

    .event_group .event_item_full {
        padding-block: 0;
        margin-block-end: 1.7rem;
        margin-inline: 0;
        padding-inline: 0.5rem;
        width: 90%;
    }

    .event_group .event_item_full .event_title {
        text-align: left;
        width: 60%;
        padding-inline-start: 1rem;
    }

    .event_group .event_item_full .event_properties {
        padding-inline: 0;
    }


    /* Icon buttons larger for fingers */
    .event_item_container .flex .button_container {
        padding-top: 0.5rem;
        column-gap: 1rem;
        margin: 0;
        /* display: none; */
    }

    /* .event_properties {
        display: none;
    } */

    .days_left {
        white-space: nowrap;
    }

    .event_item_full .event_description {
        margin: 0;
        text-align: center;
        padding-block-end: 1rem;
    }

    #full_events {
        grid-column: 1/end;
    }

    #full_calendar .drop_down_button {
        display: none;
    }

    .event_item_full {
        inline-size: 85%;
    }

    .timeline {
        width: 0;
        position: relative;
        bottom: 4rem;
    }

    .timeline .vl {
        display: none;
    }
}

#print_iframe {
    display: none;
}


/* || QUOTE*/

.quote {
    grid-row: 2;
    grid-column: 1/end;
    font-size: 1.5rem;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
    padding-block-start: 2rem;
    padding-block-end: 2rem;
    animation: bounce 0.7s ease-in;
}

.quote .box{
    background-color: rgb(209, 231, 246);
    box-shadow: none;
    background-position: cover;
    text-align: center;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
    padding-block-start: 2rem;
    padding-block-end: 5.5rem;
}

.quote #container {
    inline-size: 100%;
    block-size: 6.32rem;
    margin-block-end: 3rem;
    margin-block-start: 3rem;
}

#quote_image svg{
    object-fit: contain;
    max-width: 400px;
}


#quote_image svg path {
    /* stroke-dasharray affects how long the stroke draw lines are, higher number looks more like drawing, lower number looks like a particle effect  */

    /* stroke-dasharray length helps to be longer than the paths which effectively hides the paths    */
    stroke-dasharray: 1000;  

    /* moves the position of the dashes, which makes them animate-able */
    stroke-dashoffset: 1000;
    
    /* remove "infinite" to have it draw only once */
    animation: dash 6s ease-in forwards 2s;
    stroke:#000000;
    stroke-width:1px;
    /* depending on the drawing, may need to use stroke-miterlimit to smooth out line segments that join at a sharp angle:  https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-miterlimit */
    /*   stroke-miterlimit:10px; */
    
}
.quote .clickable_button {
    left: 40%;
}

.quote .button_container{
    float: right;
    display: flex;
}

.quote .button_container > .icon_button{
    flex: 1;
    margin-inline-end: 1rem;
}

.quote .button_container .icon_button:nth-child(2){
    animation-delay: 0.2s;
    animation-fill-mode: backwards;
}
.quote .button_container .icon_button:nth-child(3){
    animation-delay: 0.4s;
    animation-fill-mode: backwards;
}

.quote .button_container .icon_button:nth-child(4){
    animation-delay: 0.6s;
    block-size: 4rem;
    animation-fill-mode: backwards;
}

.twitter {
    display: inline;
    block-size: 3rem;
}

#container {
    font-family: 'Segoe Script', cursive;
}


#container svg {
    min-width: 100%;
}

#quote_author {
    text-align: center;
    font-size: 2rem;
    margin-block-start: 0;
    font-style: italic;
}

blockquote {
    font-size: 2rem;
    padding: 0;
    margin: 1rem 0.5rem 1rem 0.5rem;
}


/* || FOCUS*/
.focus {
    grid-row: 3;
    grid-column: 1/end;
    padding: 2rem;
    font-size: 1.5rem;
    width: 70%;
    position: relative;
    left: 15%;
}

.focus h1 {
    animation: bounce 0.7s ease-out;
}
.focus_buttons_container {
    block-size: 3rem;
    margin-block-end: 2rem;
}

.focus_buttons_container .help_button {
    float: right;
}




.focus .box {
    padding: 1rem 2rem 2rem 2rem;
    margin-block-end: 2rem;
}
.focus .steps.box {
    padding: 1rem 2rem 2rem 2rem;
    margin: 2rem 0 6rem 0;
    border-radius: 2rem;
}

.progress_bar + .buttons_container button {
    margin-top: 0;
    margin-bottom: 0;
    min-inline-size: 5rem;
}

.progress_bar {
    border: 1px solid #DDDDDD;
    margin: 0 0 1.5rem 0;
    block-size: 2rem;
    padding: 0.1rem 0.05rem 0.1rem 0.05rem;
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
    max-height: 2rem;
}

.progress {
    position: relative;
    height: 100%;
    width: 0%;
    border-radius: 1rem;
    overflow: hidden;
    background-color: #f44336;
    transition: width 1.3s ease-in-out 0.7s;
}

.progress_container {
    height: 100%;
}

.progress_container .progress span {
    float: left;
    color: white;
    opacity: 0;
    padding-inline-start: 1.5rem;
    transition: opacity 0.2s ease-in 1s;
}

.progress_container:hover .progress span {
    opacity: 1;
    transition: opacity 0.3s ease-out 0.5s;
}

.progress_secondary {
    height: 100%;
    width: 0%;
}

.show_progress {
    animation: progress_bar_move 1.5s ease-out both;
}

#focus_container {
    animation: bounce 1s ease-in;
}

#current_focus {
    font-size: 1.2em;
    display: block;
    padding-block-end: 0.7rem;
    word-wrap: break-word;
    margin: 0 0 0 0;
}

#current_focus + hr {
    margin-block-end: 2rem;
}

.focus .focus_tutorial {
    animation: fade_in_text 0.5s ease-in both;
    color: rgb(124, 124, 124, 0.7);
}

.new_focus {
    animation: reveal_new_focus 1.5s ease both;
}

.steps .goal_name {
    margin: 0 0 2rem 0;
    word-break: break-all;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    max-height: 3.5rem;
    overflow: hidden;
    white-space: nowrap;
    inline-size: 90%;
}

.steps .step_top_line {
    display: block;
}

#focus_help {
    max-block-size: inherit;
    max-inline-size: 80%;
    padding: 1rem 1rem 2rem 1rem;
    user-select: text;
    margin: auto;
}

#focus_help img{
    object-fit: contain;
    inline-size: 100%;
    margin: 2rem 0 2rem 0;
    border-radius: 1rem;
}

#focus_help h1 {
    text-align: center;
    font-size: 2.5rem;
}

#focus_help p {
    font-size: 2rem;
}

#completed_steps_container {
    margin: 8rem 0 2rem 0;
}

#completed_steps_container.hide_completed {
    block-size: calc(1.17em + 2em + 0.7rem);
    overflow: hidden;
}

#completed_steps_container .check_button {
    transform: rotate(0deg);
    transition: transform 0.3s ease-out;
}

#completed_steps_container.hide_completed .check_button {
    transform: rotate(150deg);
    transition: transform 0.3s ease-out;
}

#completed_steps_container h3 {
    cursor: pointer;
    padding: 0.7rem;
    border-radius: 1rem;
    display: inline-block;
    opacity: 0.5;
    display: flex;
    gap: 1rem;
    max-inline-size: fit-content;
    user-select: none;
    transition: opacity 0.3s ease-out, background-color 0.4s ease-out;
}

#focus_function_container{
    position: fixed;
    left: 90%;
    top: 90vh;
}

@media screen and (max-width: 999px) {

    #focus_function_container{
        left: 70%;
        top: 70vh;
    }
}



#completed_steps_container .steps {
    opacity: 0.5 !important;
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
}

#completed_steps_container .goal_description, #completed_steps_container .goal_name {
    text-decoration: line-through;
}

#fireworks {
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}

.step_top_line {
    margin-block-start: 1rem;
}
.step_top_line .button_container {
    border-bottom: 1px solid rgb(124, 124, 124, 0.3);
    padding: 0.9rem;
    inline-size: 10%;
    display: flex;
    gap: 1rem 1rem;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.steps:hover .button_container {
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.step_top_line .button_container .icon_button {
    flex-basis: 1;
}

.steps .checkmark {
    left: 0%;
    top: -17%;
    border-bottom: 0.22rem solid black;
    border-right: 0.22rem solid black;
}


.steps .goal_order {
    padding: 0.5rem;
    font-family: 'Courier New', Courier, monospace;
    inline-size: 2.5rem;
    text-align: center;
    margin: 0 1.5rem 0 0;
    display: inline-block;
    color: white;
    border-radius: 25%;
    background-color: rgb(29, 98, 149);
}

.steps .goal_description {
    background-color: rgb(242,242,242);
    overflow: hidden;
    max-height: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 2rem;
    margin-block-end: 1rem;
    word-wrap: break-word;
}

.steps .show_description {
    opacity: 1;
    max-height: 15rem;
    overflow-y: auto;
    transition: max-height 0.7s ease-out, opacity 0.3s ease-in 0.5s;
}

.steps button[title='Hide Description'] {
    transform: rotate(180deg);
    color: black;
    transition: transform 0.2s ease-out;
}

.steps button[title='Show Description'] {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
}


.step_top_line .button_container {
    display: flex;
    inline-size: auto;
    opacity: 1;
    border: none;
    justify-content: center;
    border-top: 0.07rem solid #DDDDDD;
    padding-top: 2rem;
}

@media screen and (max-width: 999px){
    .focus {
        width: auto;
        position: static;
    }
    .steps .goal_name {
        inline-size: 100%; 
    }
    
    .step_top_line {
        display: block !important;
    }

    .step_top_line .button_container {
        display: flex;
        inline-size: auto;
        opacity: 1;
        border: none;
        justify-content: center;
        border-top: 0.07rem solid #DDDDDD;
        padding-top: 2rem;
    }

    .steps .goal_description {
        padding-inline-start: 0;
        font-size: 1.5rem;
        text-align: center;
    }
}
.steps .hide_description {
    transition: max-height 0.5s ease-in;
    max-height: 0rem;
    opacity: 0;
}

.steps .button_container {
    text-align: center;
}

.steps .button_container button.description_button {
    border: none;
    outline: none;
    font-size: 2rem;
    background-color: white;
}

.steps {
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    cursor: pointer;
    user-select: none;
}

.remove_step {
    animation: delete_step 0.7s ease-out;
}


#steps_container + .clickable_button {
    z-index: 0;
}

#focus_form .focus_title {
    inline-size: 90%;
    font-size: 2.2rem;
    margin: 2rem;
    outline: none;
    border: none;
    border-block-end: 0.1rem solid black;
}

#focus_form .clickable_button {
    margin: 1rem 1rem 2rem 1rem;
    float: inline-end;
}

#step_form {
    padding: 1rem;
    inline-size: 80%;
    position: relative;
    left: 10%;
}

#step_form .step_title {
    inline-size: 100%;
    font-size: 2.2rem;
    border: none;
    border-bottom: 1px solid black;
    margin-block: 1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    outline: 0;
}

#step_form textarea {
    inline-size: calc(100% - 2rem);
    background-color: rgba(242,242,242, 1);
    padding: 1rem;
    font-size: 1.5rem;
    border: none;
    font-family: 'Segoe UI', sans-serif;
    margin-block: 1.5rem;
    block-size: 20rem;
}

/* || FEEDBACK */
.feedback {
    font-size: 1.5rem;
    padding: 2rem;
}

.feedback form {
    inline-size: 50%;
    position: relative;
    left: 25%;
}

.rc-tabs-nav-list {
    display: flex;
    margin-inline-start: 2rem;
    gap: 2rem;
    margin-block-end: 1rem;
}

.rc-tabs-nav-operations {
    display: none;
}
.rc-tabs-nav-list .rc-tabs-tab {
    animation: bounce 0.7s ease-out;
    color: rgba(29, 98, 149, 0.5);
    cursor: pointer;
    padding-inline-end: 2rem;
    transition: color 0.2s ease-out;
    border-inline-end: 0.1rem solid rgb(124,124,124);
    overflow: hidden;
}

.rc-tabs-nav-list .rc-tabs-tab:nth-child(2) {
    border-inline-end: 0;
    
}

.rc-tabs-nav-list [role="tab"] {
    box-sizing: border-box;
    block-size: 2.7rem;
    transition: color 0.2s ease-out;

}

.rc-tabs-nav-list .rc-tabs-tab:hover [role="tab"] {
    color: black;
    transition: all 0.4s ease-in;
}

.rc-tabs-nav-list [aria-selected="true"] {
    color: rgba(29, 98, 149, 1);
    transition: color 0.3s ease-in;
}

.rc-tabs-nav-list:last-child {
    border-inline-end: none;
}



/* Adds the add animation with delay */
.feedback .box {
    animation: bounce 0.7s ease-out both;
}

.feedback .box:nth-child(2) {
    animation-delay: 0.5s;
}

.feedback img:nth-child(2) {
    animation: bounce 0.7s ease-out both 0.5s;
}

.feedback .box:nth-child(3) {
    animation-delay: 1s;
}


.feedback .box:nth-child(4) {
    animation-delay: 1.5s;
}

.feedback .box:nth-child(5) {
    animation-delay: 2s;
}

.feedback .box:nth-child(6) {
    animation-delay: 2.5s;
}

/* Changes the width of form for smaller screens */
@media screen and (max-width: 999px) {
    .feedback form {
        inline-size: 100%;
        left: 0%;
    }
}

.feedback .box {
    margin-block-end: 2rem;
    padding: 2rem;
}



.feedback .box .radio_button_container {
    display: flex;
    margin-block: 1.5rem;
    gap: 1rem;
}


.feedback .box .additional_description {
    display: block;
    margin: 0;
    max-height: 0;
    opacity: 0;
}

.feedback img {
    block-size: 30rem;
    inline-size: 100%;
    object-fit: cover;
    object-position: center;
    margin-block-end: 2rem;
}

.feedback .box .show_description_input {
    opacity: 1;
    max-height: 5rem;
    margin: 3rem 0 2rem 0;
    transition: max-height 1.5s ease-out, margin 0.5s ease-out, opacity 0.3s ease-in 0.3s;
}

.feedback .box .radio_button_container input[type="radio"]::before {
    box-shadow: inset 1rem 1rem rgba(29, 98, 149, 1);
}

.feedback .required_asterisk {
    user-select: none;
    color: red;
    font-size: 1rem;
    margin-inline-start: 0.7rem;
}

.feedback .button_container {
    margin-inline-start: 2rem;
}

.feedback .button_container button {
    margin-inline-end: 2rem;
}

.feedback textarea {
    font-size: 1.5rem;
}

.feedback textarea[type="text"] {
    display: block;
    inline-size: calc(100% - 1rem);
    margin: 2rem 0 2rem 0;
    /* To remove trashy default styling */
    outline: none;
    border: none;
    border-block-end: 0.1rem solid rgba(124,124,124,0.8);
    font-family: inherit;
    height: 2.5rem;
    max-height: 10rem;
}


/* RANDOM STYLES */

.flex {
    display: flex;
    vertical-align: middle;
    justify-content: center;
}

.header {
    justify-content: left;
    align-items: center;
    block-size: 5rem;
}

.grid_wrapper { /*should this even be a grid? A flexbox will still work but it won't be as responsive i think.*/
    display: grid;
}

.box {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
    /*
    transition-property: box-shadow, transform;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(.16,1,.29,.99);
    */
}


.center_vertical { /*Centers elements vertically and horizontallt*/
    display: flex;
    justify-content: center;
    align-items: center;
}

/* || ABOUT */
.about {
    font-size: 1.5rem;
    padding: 0 2rem 2rem 2rem;
}

.about .our_mission {
    background-image: url(../images/about_image.avif);
    background-size: cover;
    background-position: 50% 5%;
    block-size: 50rem;
    animation: bounce 0.7s ease-in;
    text-align: left;
}

.about .our_mission h1 {
    position: relative;
    padding-inline-start: 2rem;
    top: 30%;
    animation: fade_in 0.7s ease-out 0.4s both;
}

.about div.about_info.our_mission p {
    padding-inline-start: 2rem;
    position: relative;
    left: 0;
    width: 30%;
    top: 30%;
    opacity: 0;
    animation: fade_in 0.7s ease-out 0.4s both;
}

.about div.about_info {
    margin-block-end: 8rem;
}

.about div.features ul {
    list-style-type: circle;
    padding-inline-start: 3rem;
}

.about div.features ul li {
    margin-block-end: 0.5rem;
}

.about div.about_info p {
    padding-inline-start: 1rem;
    width: 80%;
    position: relative;
}

.about .about_flex {
    display: flex;
    gap: 2rem;
}

.about_flex > div {
    width: 50%;
}
@media screen and (max-width: 999px) {
    .about .our_mission {
        background-image: none;
        background-color: rgb(124, 124, 124);
        color: white;
        padding-block-end: 1rem;
        background-size: cover;
        background-position: 50% 5%;
        block-size: auto;
    }
    .about div.about_info.our_mission p {
        opacity: 1;
        position: static;
        width: 90%;
        animation: none;
    }
    .about .about_flex {
        display: block;
        gap: 0;
    }
    .about div.about_info {
        width: 100%;
    }
    .about div.about_info p {
        width: 100%;
        left: 0;
    }
    .about div.about_info h1 {
        position: static;
    }
}

.about h1 {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-block-end: 0rem;
}

/* || UTILITES */
a {
    font-size: inherit;
    color: inherit;
    cursor: pointer;
}

li a {
    text-decoration: none;
}

li a:hover
{
  text-decoration: underline;
}

p a {
    color: blue;
}

p a:visited {
    color: purple;
}

.tab {
    border-radius: 50%;
    background-color: white;
    border: 1px solid rgb(124, 124, 124);
    padding: 0.2rem 0.2rem;
    margin-inline-end: 0.8rem;
}

.show {
    visibility: visible;
    opacity: 1 !important;
}

button {
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.buttons_container button {
    margin-inline-end: 2rem;
}



.current {
    background-color: black;
}

.tab_container {
    padding-block-end: 2rem;
    padding-block-start: 3rem;
    opacity: 0;
}

.root_checkmark {
    border-radius: 50%;
    margin-inline-end: 1.5rem;
    min-inline-size: 2rem;
    block-size: 2rem;
    display: inline-flex;
    align-items: center;
    border: 0.15rem solid rgb(124, 124, 124);
    background-color: white;
  }


.checkmark {
    display: inline-block;
    position: relative;
    left: 32%;
    bottom: 10%;
    height: 0.9rem;
    width: 0.5rem;
    transform: rotate(45deg);
    border-bottom: 0.22rem solid rgb(124, 124, 124);
    border-right: 0.22rem solid rgb(124, 124, 124);
    opacity: 0;
    border-radius: 0.1rem;
    transition: opacity 0.15s ease-in;
}

.checkmark.show_checkmark {
    opacity: 1;
    transition: opacity 0.2s ease-out;
}


/* || MODAL */

.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 999px) {
    .modal {
        align-items: baseline;
    }

    .modal_content_wrapper {
        top: 3vh;
        position: relative;
    }
}

.open_modal {
    animation: fade_in_text 0.1s ease-in both;  
}

.close_modal {
    animation: remove_modal 0.4s ease-in both;
}

.modal_content_wrapper {
    background-color: white;
    min-block-size: 5rem;
    border-radius: 1rem;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.show_modal_content {
    animation: show_modal 0.3s ease-in both;
}

.fade_out_modal {
    animation: fade_out 0.7s ease-out both;
}

/*The tab container doesn't fade in and out (when hovered) if the device is mobile.
This is because the 'hovering' isn't common on mobile */
@media screen and (max-width: 999px) {
    .tab_container {
        opacity: 1 !important; 
    }
}

.slide_button:hover {
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
}


/*#events_list li :hover{
    background-color: rgb(209, 231, 246);
}*/

main .grid_wrapper {
    column-gap: 4rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    /*1st row timetable and calendar, 2nd row for quote of the day, 3rd row for functions*/
}
/* || FORM STYLING */
#form {
    font-size: 1rem;
    inline-size: 70%;
    position: relative;
    left: 0%;
    padding-block-start: 3rem;
}

#form label {
    font-size: 1.5rem;
    margin: 0.5rem;
}

#form .priority_radio_btn_container {
    border-radius: 0.5rem;
    background-color: rgb(242,242,242);
    justify-content: middle;
    vertical-align: middle;
}

#form input {
    font-size: 1.5rem;
}

#form .grid_wrapper {
    grid-template-rows: auto auto;
    grid-template-columns: fit-content(50%) fit-content(100%);
    row-gap: 1rem;
    column-gap: 2rem;
}


#form .event_title {
    inline-size: calc(100% - 2rem);
    font-size: 2.2rem;
    border: none;
    border-bottom: 1px solid black;
    margin-block-end: 1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    outline: 0;
}


#form .event_due_date {
    border: none;
    border-radius: 0.5rem;
    font-family: 'Segoe UI';
    background-color: rgb(242,242,242);
    user-select: none;
}

input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
    display: none; 
}

input[type="date"]::-webkit-inner-spin-button {
    display: none;
}

#form textarea {
    inline-size: calc(100% - 2rem);
    background-color: rgba(242,242,242, 1);
    padding: 1rem;
    font-size: 1.5rem;
    border: none;
    font-family: 'Segoe UI', sans-serif;
    margin-block: 1.5rem;
    block-size: 15rem;
}

#form .clickable_button {
    margin: 1rem 0 3rem 0;
    min-width: none;
}

#form .delete_button {
    float: right;
}


@media screen and (max-width: 999px) {
    #form {
        padding: 0;
    }
    #form .grid_wrapper {
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: auto;
    }

    .priority_radio_btn_container {
        grid-row: 2;
    }

    label[for='due_date'] {
        grid-row: 3;
    }

    .event_due_date {
        grid-row: 4;
    }
    #form textarea {
        block-size: 4rem;
    }    
}

/* || ALERTS */
#alert {
    padding: 2rem;
    max-inline-size: 50%;
}

/*Alert size smaller for mobile*/
@media screen and (max-width: 999px) {
    #alert {
        max-inline-size: 80%;
    }
}

#alert .content {
    inline-size: 100%;
}

#alert .icon_container {
    text-align: center;
}
#alert i {
    margin-inline-end: 1rem;
    user-select: none;
    text-align: center;
    font-size: 6rem;
    color: rgba(29, 98, 149);
    display: inline;
}

.alert_title {
    font-size: 2rem;
    text-transform: capitalize;
    text-align: center;
    padding-block: 1rem;
    border-block: 0.2rem solid rgba(29, 98, 149);
}

.alert_desc {
    text-align: center;
    display: block;
    background-color: rgba(242,242,242, 1);
    border-radius: 0.5rem;
    font-size: 1.3rem;
    margin: 0;
    padding: 2rem;
    overflow: hidden;
    max-width: 100%;
}

#alert button{
    margin-inline: 3rem;
    margin-block: 1.5rem;
}

/* || LOGIN */
.login  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    animation: fade-top 0.3s ease-in;
}

.login hr {
    inline-size: 60%;
}

.login p {
    font-size: 1.5rem;
    width: 50%;
    color: rgb(124, 124, 124);
    text-align: center;
}

.login img {
    border-radius: 30%;
    background-color: white;
    width: 10%;
    padding: 2%;
    overflow: visible;
}

/* .login button {
    transform: scale(0);
    animation: span 0.5s ease-in 0.3s forwards;
} */

@media screen and (max-width: 999px) {
    .login img {
        min-width: 50%;
        padding: 5%;
    }
}


/* || MOBILE LAYOUT */
@media screen and (max-width: 999px) {
    /* font-size and layout for mobile devices. 
    Essentially this will increase font size 
    for smaller res machines and also stack the boxes differently.*/
    html {
        font-size: 25px;
    }

    .box {
        padding-inline: 0.5rem;
    }

    .box, .timetable {
        grid-column: 1/end;
    }
    .calendar {
        grid-row: 2;
        display: none;
    }
    .quote {
        grid-row: 3;
    }
    .focus {
        grid-row: 4;
        grid-column: 1/end;
    }
    .notes {
        grid-row: 5;
        grid-column: 1/end;
    }
}

/*Larger font for high-res screens*/
@media (max-width: 1980px) and (min-width: 1600px) {
    html {
        font-size: 15px;
    }
    
}


@media print {
    header {
        visibility: collapse;
        block-size: 0rem !important;
    }

    #full_calendar {
        visibility: collapse;
        block-size: 0rem !important;
    }

    .quote .button_container, h1 {
        visibility: collapse;
        block-size: 0rem !important;
        inline-size: 0rem !important;
    }

    main {
        background-color: white;
    }

    #print_iframe {
        display: block;
        background-color: white;
    }

    #print_iframe .print_event_group {
        /* box-shadow: 0 4px 8px rgb(0 0 0 / 20%); */
        padding: 5mm;
        margin-block-end: 5mm;
        border-radius: 1rem;
        break-inside: avoid-page;
    }

    #print_iframe .print_event_group .print_event {
        display: flex;
    }

    #print_iframe .print_event_group .print_event {
        padding: 2mm;
        border-block-start: 1px solid rgba(200,200,200, 1);
    }

    #print_iframe .print_event_group > .day_divider ~ .print_event:last-child {
        border-block-end: 1px solid rgba(200,200,200, 1);
    }
    #print_iframe .print_event_group > .print_event:last-child {
        border-block-end: 1px solid rgba(200,200,200, 1);
    }

    #print_iframe .print_event_group .day_divider {
        font-size: 2rem;
        margin-block: 3mm;
        font-weight: bold;
    }

    #print_iframe .print_event_group .print_event div{
        width: 15%;
        font-size: 1.5rem;
        text-align: center;
    }

    #print_iframe .print_event_group .print_event .print_event_title{
        width: 80%;
        text-align: left;
    }

    #print_iframe .print_event_group .print_event .print_event_checkbox{
        width: 5%;
    }

    #print_iframe .print_event_group .print_event .check_box{
        width: 1.5rem;
        border-radius: 50%;
        height: 1.5rem;
        border: 1px solid black;
    }

    #print_iframe .print_month {
        font-weight: bold;
        font-size: 2rem;
        border-bottom: 0.1rem solid black;
    }

    
    
}