/*Alex*/
/*Contains CSS animations which are going to be used*/
/* || SVG AND ANIMATIONS*/
.line {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: move 0.8s linear;
    animation-fill-mode: forwards;
}

.timetable, .calendar {
    animation-name: fade-top;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
}

.fade_in_txt {
    animation: fade_in_text 0.7s ease-out;
}

.span {
    animation: span 0.4s ease-in;
}

.fade_out {
    animation: fade_out 0.4s ease-in;
}

.move_up2 {
    animation: bounce2 0.9s ease-in;
}

.move_up {
    animation: bounce 0.9s ease-out both;
}


.quote .box {
    animation: bounce 0.7s ease-out forwards;
}

#container {
    min-height: 2rem;
}


    /* the animation */
    @keyframes dash {
      to {
        /* closing the offset makes the line appear to be drawn-in */
        stroke-dashoffset: 0;
      }
    }

@keyframes collapse {
    to {
        max-height: 3rem;
    }
}

@keyframes expand {
    from {
        max-height: 3rem;
    }
    to {
        max-height: initial;
    }
}

/*This animation plays when user hovers over a row. Highlights the row basically */
@keyframes highlight {
    0% {
        background-color: initial;
    }
    
    100% {
        background-color: rgba(209,231,246, 0.5);
    }
}


@keyframes hover_list {
    0% {
        background-color: rgba(242,242,242,0.7);
    }
    100% {
        background-color: rgba(209,231,246, 0.5);
    }
}


@keyframes fade_out {
    0% {

    }
    
    100% {
        opacity: 0;
    }
}

@keyframes drop_down_reveal {
    0% {
        max-height: 0;
        overflow: hidden;
    }

    100% {
        max-height: 20rem;
    }
}

@keyframes fade_in {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
    
}

/*This is the animation that draws the lines*/
@keyframes move {
    0% {
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 0;
        opacity: 1;
    }
}
/*This is the animation that plays when a box/card is loaded*/
@keyframes fade-top {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    62.5% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/*This is the animation that plays when the user scrolls down. Displays the quote of the day and the Current focus*/
@keyframes bounce {
    0%{
        opacity: 0;
        background-color: inherit;
        transform: translateY(4rem);
        -moz-transform: translateY(4rem);
        -ms-transform: translateY(4rem);
        -o-transform: translateY(4rem);
    }
    33% {
        transform: translateY(-2rem);
        -moz-transform: translateY(-2rem);
        -o-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        opacity: 1;
    }

    66% {
        transform: translateY(1rem);
        -moz-transform: translateY(1rem);
        -o-transform: translateY(1rem);
        -ms-transform: translateY(1rem);
        opacity: 1;
    }

    100% {
        transform: translate(0%);
        -moz-transform: translateY(0%);
        -o-transform: translateY(0rem);
        -ms-transform: translateY(0rem);
        opacity: 1;
    }
}


@keyframes completed_bounce {
    0%{
        opacity: 0;
        background-color: inherit;
        transform: translateY(4rem);
    }
    33% {
        transform: translateY(-2rem);
        opacity: 0.5;
    }

    66% {
        transform: translateY(1rem);
        opacity: 0.5;
    }

    100% {
        transform: translate(0%);
        opacity: 0.5;
    }
}

@keyframes shine {
    0% {
      background-position: right;    
    }
    /*100% {
      background-position: left; it's the default value, no need to define it
    }*/
  }
  

@-webkit-keyframes pulse {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    85% {
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes pulse {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    85% {
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }  
@keyframes table_slide {
    0%{
        opacity: 0.5;
        background-color: inherit;
        transform: translateX(-4rem);
    }
    33% {
        transform: translateX(2rem);
        opacity: 1;
    }

    66% {
        transform: translateX(-1rem);
        opacity: 1;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


@keyframes bounce2 {
    0%{
        opacity: 0;
        background-color: inherit;
        transform: translateY(4rem);
    }
    33% {
        transform: translateY(-2rem);
        opacity: 1;
    }

    66% {
        transform: translateY(1rem);
        opacity: 1;
    }

    100% {
        transform: translate(0%);
        opacity: 1;
    }
}

/*Fade in animation but for text*/
@keyframes fade_in_text {
    from {
        opacity: 0;
        transform: perspective(500px) translate(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    
    to {
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}

@keyframes progress_bar_move {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(50%);
    }
}


@keyframes complete_event {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }


    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes show_search {
    0% {
        color: black;
        background-color: inherit;
    }

    100% {
        color: white;
        background-color: rgb(124,124,124);
    }
}

@keyframes add_event {
    0% {
        opacity: 0;
        transform: translateX(-5rem)
    }

    30% {
        opacity: 0.6;
        transform: translateX(2rem);
    }

    100%{
        opacity: 1;
        transform: translateX(0%)
    }
}

@keyframes show_header {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes hide_header {
    0% {
        transform: translateY(0%)
    }
    100% {
        transform: translateY(-100%)
    }
}


@keyframes show_tooltip {
    0% {
        opacity: 0;
        transform:rotateY(45deg);
        transform: translateY(-20rem);
        /*transform: scale(0%);*/
    }

    100% {
        opacity: 1;
        transform: rotateY(0deg);
        transform: translateY(-25rem);
        /*transform: scale(100%);*/
    }
}
@keyframes remove_modal {
    0% {
        opacity: 1;
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    
    100% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }

}

@keyframes show_modal {
    0% {
        transform: scale(0.0);
        -webkit-transform: scale(0);
    }
    
    100% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    
}


@keyframes span {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
      }
      85% {
        -webkit-transform: scale(1.25);
                transform: scale(1.25);
      }
      to {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
}

@keyframes span2 {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
      }
      85% {
        -webkit-transform: scale(1.25);
                transform: scale(1.25);
      }
      to {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
}

@keyframes delete_step {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes reveal_new_focus {
    from {
        opacity: 0;
        transform: perspective(500px) translate(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    
    to {
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}
